<template>
  <section v-if="faqs && faqs.length" class="faq-section">
    <div class="faq-section__container">
      <ul class="faq">
        <li class="faq__item" v-for="(faq, i) in faqs" :key="i">
          <button type="button" class="faq__header" @click="toggleFaq(i)">
            <span>{{ faq.title }}</span>
            <RemixIconComponent category="System" :name="activeFaq === i ? 'subtract-line' : 'add-line'" />
          </button>
          <div class="faq__body" v-if="activeFaq === i">
            <EditorJSComponent :text="JSON.parse(faq.answer)" />
          </div>
        </li>
      </ul>
    </div>
  </section>
  <span v-else>Информация отсутствует</span>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "FaqSection",
  props: {
    noTitle: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeFaq: null,
    };
  },
  computed: {
    faqs() {
      return this.$store.state.faq_page.faqs;
    },
  },
  methods: {
    toggleFaq(i) {
      this.activeFaq = this.activeFaq === i ? null : i;
    },
  },
  components: { EditorJSComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.faq-section {
  display flex
  margin-bottom 80px
  position relative
  overflow-x hidden
  +below(1200px) {
    margin-bottom 30px
  }

  &__container {
    max-width 915px
    width 100%
    display grid
    padding 0 30px
    +below(420px) {
      padding 0
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 26px;
    text-align: center;
    margin 20px 0 70px
    max-width 777px
    +below(1200px) {
      margin 20px 0 10px
    }
  }

  &__background {
    width 425px
    height 425px
    flex-shrink 0

    &--top {
      absolute right top
      transform translateX(50%)
    }

    &--bottom {
      absolute left bottom
      transform translateX(-50%)
    }
  }

}

.faq {
  list-style none
  padding 0
  margin 0
  display grid

  &__item {
    border-bottom 1px solid var(--blue-400)
    width 100%
    display grid
  }

  &__header {
    font-size: 1.375em;
    padding 20px 0
    text-align left
    background none
    width 100%
    display flex
    align-items center
    justify-content space-between
    border none
    cursor pointer
    transition var(--transition)
    +below(1200px) {
      font-size 1em
      padding 10px 0
    }

    &:hover {
      color var(--accent)

      .icon svg path {
        fill var(--accent)
      }
    }

    .icon {
      width 24px
      height 24px
      +below(1200px) {
        width 16px
        height 16px
      }

      svg path {
        transition var(--transition)
      }
    }
  }

  &__body {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 28px;
    padding 10px 0 20px
    +below(1200px) {
      font-size: 0.875em;
      line-height: 20px;
      padding 10px 0
    }
  }
}
</style>
