import { render, staticRenderFns } from "./FaqSection.vue?vue&type=template&id=4322ad6e"
import script from "./FaqSection.vue?vue&type=script&lang=js"
export * from "./FaqSection.vue?vue&type=script&lang=js"
import style0 from "./FaqSection.vue?vue&type=style&index=0&id=4322ad6e&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports